/* eslint-disable no-useless-escape */
export default class ConstDef {
  static apiUrl = ''
  static apiUrlProduction = ':443/HiSFS/restApi'
  static apiUrlEvaluation = ':8082/restApi'
  static stompPortProduction = ':443/HiSFS/ws'
  static stompPortEvaluation = ':8082/ws'

  static manualUrl = ''
  static manualUrlProduction = ':443/manual'
  static manualUrlEvaluation = ':4200/manual'
  static stompUrl = ''

  static DATA_BASE = 'HiBNH'

  static WEB_TAB_TITLE='홍익 Hi-SFS(MES)'
  static TAB_TITLE='홍익 Hi-SFS(MES)'

  // Look Up
  static YN_LOOKUP = [
    {
      코드: 'N'
    },
    {
      코드: 'Y'
    }
  ]

  static USER_PERMISSION = [
    { groupName: '시스템관리자', permission: 1 },
    { groupName: '생산관리', permission: 2 },
    { groupName: '물류관리', permission: 4 },
    { groupName: '구메관리', permission: 8 },
    { groupName: '수리/보전관리', permission: 16 },
    { groupName: '생산현장관리자', permission: 32 },
    { groupName: '생산현장직', permission: 64 }
  ]

  static WEEK_PLAN_KEY = 'WEEK_PLAN'

  // packet command
  static CONNECT = 0x0000;
  static LOGOUT = 0x0001;
  static EXEC_PROC = 0x0011;
  static EXEC_PROC_STREAM = 0x0012;
  static EXEC_PROC_POI = 0x0021;
  static CONV_GEO_XY = 0x0022;
  static PROC_ALLOC = 0x0031;
  static PAY_CREDIT_CARD = 0x0101;
  static CANCEL_CREDIT_CARD = 0x0102;
  static CLOSE_FORCE = 0xF001;

  // packet result
  static SUCCESS = 0x01;
  static CONTINUE = 0x02;
  static COMPLETE = 0x03;
  static ERR_MESSAGE = 0x10;
  static ERR_SERVER = 0x11;
  static ERR_JSON = 0x12;
  static ERR_NOUSER = 0x16;
  static ERR_PASSWORD = 0x17;
  static ERR_PACKETVER = 0x18;
  static ERR_SESSIONID = 0x19;
  static ERR_PARAMS = 0x21;
  static NETWOTK_RECONNECT = 0x99;
  static ERR_NETWOTK_RECONNECT= 0x98;

  // WAS Error Reponse
  static UNAUTHORIZED = 401
  static NOT_FOUND = 404
  static FORBIDDEN = 403
  static BAD_REQUEST = 400
  static NOT_ACCEPTABLE = 406
  static GENERRAL_ERROR = 500
  static DUPLICATE_LOGIN = 1009

  // BASE Data Code
  // 품목유형
  static 품목유형 = 'S01'
  static 구매품 = 'S0106'
  static 다공정품 = 'S0104'
  static 반제품 = 'S0102'
  static 완제품 = 'S0100'
  // 라인상태
  static 생산라인정상 = 'B2500'
  // 자재유형
  static 자재유형 = 'S04'
  static 자재유형_원자재 = 'S0402'

  static 공정유형 = 'B02'
  static 단위 = 'B11'
  static 직급 = 'B09'
  static 직책 = 'B10'
  static 장소유형 = 'B08'
  static 설비유형 = 'B15'
  static 생산진행상태 = 'B25'
  static 고장계통 = 'B26'
  static 고장유형 = 'B27'

  // 근무형태
  static 근무형태 = [
    { 코드명: '교대없음', 코드: 1 },
    { 코드명: '일 2교대', 코드: 2 },
    { 코드명: '일 3교대', 코드: 3 }
  ]

  static 자재출고 = 0
  static 자재입고 = 1
  static 불량품출고 = 4
  static 불량품반품 = 5 // 재공창고로반품
  static 제품출하 = 2
  static 제품반품 = 3
  static 폐기처리 = 6
  // 거래처구분
  static COMPANY_TYPE = [
    { 코드명: '발주사', 코드: '발주사' },
    { 코드명: '협력사', 코드: '협력사' }
  ]

  // 자재/제품 수불
  static PURCHASE_INOUT_CODE = [
    { 코드명: '자재출고', 코드: 0 },
    { 코드명: '자재입고', 코드: 1 },
    { 코드명: '불량출고', 코드: 4 }
  ]

  static PRODUCTION_INOUT_CODE = [
    { 코드명: '제품출하', 코드: 2 },
    { 코드명: '제품반품', 코드: 3 }
  ]

  static GOODS_INOUT_CODE = [
    { 코드명: '자재출고', 코드: 0 },
    { 코드명: '자재입고', 코드: 1 },
    { 코드명: '제품출하', 코드: 2 },
    { 코드명: '제품반품', 코드: 3 },
    { 코드명: '제품폐기', 코드: 6 }
  ]

  static GOODS_INOUT_CODE_ALL = [
    { 코드명: '출고', 코드: 0 },
    { 코드명: '입고', 코드: 1 },
    { 코드명: '출하', 코드: 2 },
    { 코드명: '반품', 코드: 3 },
    { 코드명: '폐기', 코드: 6 }
  ]

  // 불량수불
  static BAD_INOUT_CODE = [
    { 코드명: '불량반품', 코드: 4 },
    { 코드명: '불량입고', 코드: 5 },
    { 코드명: '폐기처리', 코드: 6 }
  ]

  static BAD_PROCESSING_STATE = [
    { 코드명: '미처리', 코드: 0 },
    { 코드명: '불량반품', 코드: 4 },
    { 코드명: '폐기처리', 코드: 6 }
  ]

  static BOM_TYPE = [
    { 코드: 0, 코드명: 'BOM 1차 품목' },
    { 코드: 1, 코드명: 'BOM 전 품목' }
  ]

  // SAP operationCode
  static INPUT = '11';
  static INPUT_LOAD = '12'
  static INPUT_RETURN = '13'
  static INPUT_RETURN_LOAD = '14'
  static OUTPUT = '21'
  static OUTPUT_LOAD = '22'
  static OUTPUT_RETURN = '23'
  static OUTPUT_RETURN_LOAD = '24'

  // 유효기간
  static 유효기간 =[
    { value: 3, text: '3개월' },
    { value: 6, text: '6개월' },
    { value: 9, text: '9개월' },
    { value: 12, text: '12개월' },
    { value: 15, text: '15개월' },
    { value: 18, text: '18개월' },
    { value: 21, text: '21개월' },
    { value: 24, text: '24개월' },
    { value: 27, text: '27개월' },
    { value: 30, text: '30개월' },
    { value: 33, text: '33개월' },
    { value: 36, text: '36개월' },
    { value: 42, text: '42개월' },
    { value: 48, text: '48개월' },
    { value: -1, text: '없음' }
  ]

  static OP_TYPE_KOOKUP = [
    { 코드: '11', 코드명: '자재입고' },
    { 코드: '12', 코드명: '자재입고' },
    { 코드: '13', 코드명: '불량출고' },
    { 코드: '14', 코드명: '불량출고' },
    { 코드: '완제품입고', 코드명: '완제품입고' },
    { 코드: '고객반품', 코드명: '고객반품' },
    { 코드: '일반출하', 코드명: '일반출하' },
    { 코드: '택배출하', 코드명: '택배출하' }
  ]

  /*
  // user Group
  static SYSTEM_MANAGER = 1
  static TOP_MANAGER = 2
  static YUNHAP_MANAGER = 4
  static HEAD_NANAGER = 8
  static BRANCH_MANAGER = 16
  static GEN_MANAGER = 32
  static BILL_MANAGER = 64
  static COUNCELOR = 128
*/
  // chart palette
  static CHART_PALETTE =[
    'Harmony Light',
    'Soft',
    'Soft Pastel',
    'Pastel',
    'Violet',
    'Bright',
    'Soft Blue',
    'Office',
    'Ocean',
    'Carmine',
    'Dark Moon',
    'Vintage',
    'Material',
    'Green Mist'
  ]

  static QR_PRINT = true
  static ENODE_BASE64 = false
  static PALLET_NO_ZPL = '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD18^JUS^LRN^CI0^XZ' +
  '^XA' +
  '^MMT' +
  '^PW559' +
  '^LL0400' +
  '^LS0' +
  '^FO44,37^GB480,327,2^FS' +
  '^FO44,97^GB478,0,2^FS' +
  '^FO98,37^GB0,60,2^FS' +
  '^FT52,83^A0N,34,33^FB40,1,0,C^FH\^FDNO^FS' +
  '^FT113,81^A0N,34,88^FB387,1,0,C^FH\^FD{{TITLE}}^FS' +
  '^FT174,357^BQN,2,9' +
  '^FH\^FDLA,{{QR_CODE}}^FS' +
  '^PQ1,0,1,Y^XZ';

  static PALLET_NO_ZPL_OLD = '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR3,3~SD20^JUS^LRN^CI0^XZ' +
    '^XA' +
    '^MMT' +
    '^PW559' +
    '^LL0400' +
    '^LS0' +
    '^FO14,15^GB531,371,2^FS' +
    '^FO16,74^GB530,0,2^FS' +
    '^FO97,17^GB0,60,2^FS' +
    '^FT36,58^A0N,34,33^FB40,1,0,C^FH\^FDNO^FS' +
    '^FT115,58^A0N,34,88^FB387,1,0,C^FH\^FD{{TITLE}}^FS' +
    '^FT174,353^BQN,2,10' +
    '^FH\^FDLA,{{QR_CODE}}^FS' +
    '^PQ1,0,1,Y^XZ';
}
