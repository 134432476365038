
import HttpService from '../HttpService'
import ParamModel from '../../domain/ParamModel'
import AesCrypto from '../AesCrypto'
import ConstDef from '../../../../../BnhMesVueWin/src/share/ConstDef'
export default class StoreProc {
  static CONNECT = 0x0000
  static LOGOUT = 0x0001
  static EXEC_PROC = 0x0011
  static EXEC_PROC_STREAM = 0x0012
  static EXEC_PROC_POI = 0x0021
  static CONV_GEO_XY = 0x0022
  static PROC_ALLOC = 0x0031
  static CALL_REST_API = 0x0032
  static PAY_CREDIT_CARD = 0x0101
  static CANCEL_CREDIT_CARD = 0x0102
  static CLOSE_FORCE = 0xF001

  static makeParamModel (params) {
    const param = new ParamModel()
    const plainText = JSON.stringify(params)
    const encode = AesCrypto.GetEncryptString(plainText)
    param.command = this.CALL_REST_API
    param.body = encode
    return param
  }

  static runFindProc (storeProc, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      proc: storeProc,
      ps: params // find: object, update/delete : array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.select(param)
  }

  static runNoEncodeFindProc (storeProc, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      proc: storeProc,
      ps: params // find: object, update/delete : array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.noEncodeSelect(param)
  }

  static runUpdateProc (storeProc, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      proc: storeProc,
      ps: params // find: object, update/delete : array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.update(param)
  }

  static runDeleteProc (storeProc, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      proc: storeProc,
      ps: params // find: object, update/delete : array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.update(param)
  }

  static runFindSqlProc (table, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlSelect(param)
  }

  static runNoEncodeFindSqlProc (table, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlNoEncodeSelect(param)
  }

  static runInsertSqlProc (table, exceptKey, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      exceptKey: exceptKey,
      ps: params // array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsert(param)
  }

  static runInsertAndFindSqlProc (table, pk, exceptKey, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk,
      exceptKey: exceptKey,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsertAndFind(param)
  }

  static runInsertAndUpdateIdWithSurfixSqlProc (table, pk, exceptKey, autoIdFieldName, updateFieldName, idPrefix, idSurfix, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk,
      idPrefix: idPrefix,
      idSurfix: idSurfix,
      autoIdFieldName: autoIdFieldName,
      updateFieldName: updateFieldName,
      exceptKey: exceptKey,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsertAndUpdateId(param)
  }

  static runInsertAndUpdateIdWithAutoIdSurfixSqlProc (table, pk, exceptKey, autoIdFieldName, updateFieldName, prefix, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk,
      prefix: prefix,
      autoIdFieldName: autoIdFieldName,
      updateFieldName: updateFieldName,
      exceptKey: exceptKey,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsertAndUpdateAutoIdSurfix(param)
  }

  static runInsertAndUpdateIdSqlProc (table, pk, exceptKey, autoIdFieldName, updateFieldName, idPrefix, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk,
      exceptKey: exceptKey,
      idPrefix: idPrefix,
      autoIdFieldName: autoIdFieldName,
      updateFieldName: updateFieldName,
      ps: params // object
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsertAndUpdateId(param)
  }

  static runInsertOrUpdayeSqlProc (table, pk, exceptKey, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk,
      exceptKey: exceptKey,
      ps: params // array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlInsertOrUpdate(param)
  }

  static runUpdateSqlProc (table, pk, exceptKey, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      pk: pk, // string array
      exceptKey: exceptKey,
      ps: params // array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlUpdate(param)
  }

  static runDeleteSqlProc (table, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      ps: params // array
    }
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = this.EXEC_PROC
    param.body = encode
    return HttpService.runtimeSqlDelete(param)
  }

  static syncFindProc (JQ, storeProc, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      proc: storeProc,
      ps: params // find: object, update/delete : array
    }
    let result = null
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = ConstDef.EXEC_PROC
    param.body = encode
    JQ.ajax({
      type: 'POST',
      url: ConstDef.apiUrl + '/spSelect',
      data: JSON.stringify(param),
      dataType: 'json',
      contentType: 'application/json',
      xhrFields: { withCredentials: true },
      async: false,
      success: function (data) {
        result = JSON.parse(AesCrypto.GetDecryptString(data.body))
      },
      error: function (error) {
        result = {
          errorMsg: error
        }
      }
    })
    return result
  }

  static syncFindSqlProc (JQ, table, params) {
    const jsonObject = {
      db: ConstDef.DATA_BASE,
      table: table,
      ps: params // object
    }
    let result = null
    const plainText = JSON.stringify(jsonObject)
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = ConstDef.EXEC_PROC
    param.body = encode
    JQ.ajax({
      type: 'POST',
      url: ConstDef.apiUrl + '/runtimeSqlSelect',
      data: JSON.stringify(param),
      dataType: 'json',
      contentType: 'application/json',
      xhrFields: { withCredentials: true },
      async: false,
      success: function (data) {
        result = JSON.parse(AesCrypto.GetDecryptString(data.body))
      },
      error: function (error) {
        result = {
          errorMsg: error
        }
      }
    })
    return result
  }

  static deleteForwardBom (pCode) {
    return this.runUpdateProc('spDeleteReqMaterialByTitleAndPCode', [{ 완제품코드: pCode, title: 'bomForward' }])
  }

  static deleteForwardBomBySemiCode (semiCode) {
    return this.runUpdateProc('spDeleteReqMaterialByTitleAndInSemiCode', [{ 반제품코드: semiCode, title: 'bomForward' }])
  }

  static MakeModel (data) {
    return JSON.parse(data.body)
  }

  static MakeModelStore (data, store, commit) {
    const json = JSON.parse(data.body)
    store.commit(commit, json)
    return json
  }
}
