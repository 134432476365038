import axios from 'axios'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import AesCrypto from './AesCrypto'
import ParamModel from '../domain/ParamModel'

export default class HttpService {
  static connect () {
    return axios.get(ConstDef.apiUrl + '/connect', { withCredentials: true, headers: { 'X-Forwarded-For': '172.30.1.29' } })
      .then((result) => {
        return result.data
      })
  }

  static connectNoExponent () {
    return axios.get(ConstDef.apiUrl + '/auth/createKeyWithoutExponent', { withCredentials: true, headers: { 'X-Forwarded-For': '172.30.1.29' } })
      .then((result) => {
        return result.data
      })
  }

  static login (param) {
    return axios.post(ConstDef.apiUrl + '/login', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static loginNoExponent (param) {
    return axios.post(ConstDef.apiUrl + '/auth/loginWithoutExponent', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static logout () {
    return axios.get(ConstDef.apiUrl + '/logout', { withCredentials: true })
      .then((result) => {
        return result.data
      })
  }

  static reqPostParamModel (url, plainText) {
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = 0x0
    param.body = encode
    return axios.post(`${ConstDef.apiUrl}${url}`, param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static select (param) {
    return axios.post(ConstDef.apiUrl + '/spSelect', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static noEncodeSelect (param) {
    return axios.post(ConstDef.apiUrl + '/spNoEncodeSelect', param, { withCredentials: true })
      .then((result) => {
        return result.data
      })
  }

  static update (param) {
    return axios.post(ConstDef.apiUrl + '/spUpdate', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlInsert (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlInsert', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlInsertAndFind (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlInsertAndFind', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlInsertAndUpdateId (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlInsertAndUpdateId', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlInsertAndUpdateAutoIdSurfix (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlInsertAndUpdateAutoIdSurfix ', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlInsertOrUpdate (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlInsertOrUpdate', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlUpdate (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlUpdate', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlSelect (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlSelect', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static runtimeSqlNoEncodeSelect (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlNoEncodeSelect', param, { withCredentials: true })
      .then((result) => {
        return result.data
      })
  }

  static runtimeSqlDelete (param) {
    return axios.post(ConstDef.apiUrl + '/runtimeSqlDelete', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static insert (param) {
    return axios.post(ConstDef.apiUrl + '/spUpdate', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static delete (param) {
    return axios.post(ConstDef.apiUrl + '/spUpdate', param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static remoteUpdateOrderCallmart (salesDate) {
    const param = { salesDate: salesDate }
    return axios.post(ConstDef.apiUrl + '/remoteUpdateOrderCallmart', null, { params: param, withCredentials: true })
      .then((result) => {
        return result.data
      })
  }

  static GetResultModel (data) {
    if (data.body !== undefined) {
      data.body = AesCrypto.GetDecryptString(data.body)
    }
  }

  static GetResultModelArray (data) {
    for (let i = 0; i < data.body.length; i++) {
      data.body[i] = AesCrypto.GetDecryptString(data.body[i])
    }
  }

  static upload (form, url) {
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return axios.post(ConstDef.apiUrl + url, form, config)
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }

  static reqPost (json, url) {
    const param = { jsonData: json }
    return axios.post(ConstDef.apiUrl + url, null, { params: param, withCredentials: true })
      .then((result) => {
        return result.data
      })
  }

  static reqPostBody (param, url) {
    return axios.post(ConstDef.apiUrl + url, param, { withCredentials: true })
      .then((result) => {
        this.GetResultModel(result.data)
        return result.data
      })
  }
}
