import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aesKey: null,
    aesIv: null,
    userInfo: null,
    topMenu: null,
    baseData: null,
    isAutoLogin: null,
    tempArg: null,
    temp: null,
    sysParam: null,
    view: null,
    model: null,
    location: null
  },
  getters: {
    getAesKey: function (state) {
      if (state.aesKey === null) {
        state.aesKey = sessionStorage.getItem('aesKey')
      }
      return state.aesKey
    },
    getAesIv: function (state) {
      if (state.aesIv === null) {
        state.aesIvSingle = sessionStorage.getItem('aesIv')
      }
      return state.aesIv
    },
    getUserInfo: function (state) {
      if (state.userInfo === null) {
        state.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      }
      return state.userInfo
    },
    getTopMenu (state) {
      if (state.topMenu === null) {
        state.topMenu = JSON.parse(sessionStorage.getItem('topMenu'))
      }
      return state.topMenu
    },
    getBaseData (state) {
      if (state.baseData === null) {
        state.baseData = JSON.parse(sessionStorage.getItem('baseData'))
      }
      return state.baseData
    },
    getLocation (state) {
      if (state.location === null) {
        state.location = JSON.parse(sessionStorage.getItem('location'))
      }
      return state.location
    },
    getBaseDataByType: (state) => (id) => {
      const baseDataArray = []
      if (state.baseData === null) {
        state.baseData = JSON.parse(sessionStorage.getItem('baseData'))
      }
      if (state.baseData !== null) {
        for (let i = 0; i < state.baseData.length; i++) {
          if (state.baseData[i].상위코드 === id) {
            baseDataArray.push(state.baseData[i])
          }
        }
      }
      return baseDataArray
    },
    getSysParam (state) {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      return state.sysParam
    },
    getTempArgData (state) {
      return state.tempArg
    },
    getTempData (state) {
      return state.temp
    },
    getIsAutoLogin (state) {
      state.isAutoLogin = JSON.parse(localStorage.getItem('isAutoLogin'))
      if (state.isAutoLogin === undefined || state === null) {
        return null
      }
      return state.isAutoLogin
    },
    getViewWidth (state) {
      return state.view
    },
    getMobileModel (state) {
      if (state.model === null) {
        state.model = sessionStorage.getItem('mobileModel')
      }
      return state.model
    }
  },
  mutations: {
    saveAesKeyIv (state, { key, iv }) {
      state.aesKey = key
      // // console.log('disk=' + key)
      state.aesIv = iv
      sessionStorage.setItem('aesKey', key)
      sessionStorage.setItem('aesIv', iv)
    },
    saveAesKey (state, key) {
      state.aesKey = key
      sessionStorage.setItem('aesKey', key)
    },
    saveAesIv (state, iv) {
      state.aesIv = iv
      sessionStorage.setItem('aesIv', iv)
    },
    saveIsAutoLogin (state, option) {
      state.isAutoLogin = option
      localStorage.setItem('isAutoLogin', JSON.stringify(option))
    },
    deleteIsLogin (state) {
      localStorage.removeItem('isAutoLogin')
    },
    saveUserInfo (state, userInfo) {
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    saveSysParam (state, param) {
      state.sysParam = param
      sessionStorage.setItem('sysParam', JSON.stringify(param))
    },
    deleteUserInfo (state) {
      state.userInfo = null
      sessionStorage.removeItem('userInfo')
    },
    deleteAesKeyIv (state) {
      state.aesKey = null
      state.aesIv = null
      sessionStorage.removeItem('aesKey')
      sessionStorage.removeItem('aesIv')
    },
    deleteBaseData (state) {
      state.baseData = null
      sessionStorage.removeItem('baseData')
    },
    saveTopmenu (state, topMenu) {
      state.topMenu = topMenu
      sessionStorage.setItem('topMenu', JSON.stringify(topMenu))
    },
    saveBaseData (state, baseData) {
      state.baseData = baseData
      sessionStorage.setItem('baseData', JSON.stringify(baseData))
    },
    saveLocation (state, location) {
      state.location = location
      sessionStorage.setItem('location', JSON.stringify(location))
    },
    saveTempArgData (state, arg) {
      state.tempArg = arg
    },
    deleteTempArgData (state) {
      state.tempArg = null
    },
    saveTempData (state, arg) {
      state.temp = arg
    },
    deleteTempData (state) {
      state.temp = null
    },
    saveViewWidth (state, width) {
      state.view = width
    },
    saveMobileModel (state, model) {
      state.model = model
      sessionStorage.setItem('mobileModel', model)
    }
  },
  actions: {

  }
})
