import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import MyPage from '../views/MyPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      {
        path: 'purchaseDeliveryByOrder',
        name: '발주자재 입고',
        component: () => import('../views/PurchaseReceipt/PurchaseDeliveryByOrder.vue')
      },
      {
        path: 'badPurchaseTakeout',
        name: '불량자재 반송 출고',
        component: () => import('../views/PurchaseReceipt/PurchaseBadReturn.vue')
      },
      {
        path: 'productionReceiptList',
        name: '창고 입출 현황',
        component: () => import('../views/ProductionReceipt/DallyProductionReceipt.vue')
      },
      {
        path: 'productDeliveryByOrder',
        name: '완제품 입고',
        component: () => import('../views/ProductionReceipt/ProductDeliveryByOrder.vue')
      },
      {
        path: 'pickingPost',
        name: '제품 피킹(택배)',
        component: () => import('../views/PurchaseReceipt/Picking.vue')
      },
      {
        path: 'pickingGen',
        name: '제품 피킹(일반)',
        component: () => import('../views/PurchaseReceipt/PickingGen.vue')
      },
      {
        path: 'locationMove',
        name: '파레트 위치이동',
        component: () => import('../views/PurchaseReceipt/LocationMove.vue')
      },
      {
        path: 'palletMove',
        name: '파레트간 제품이동',
        component: () => import('../views/PurchaseReceipt/PalletMove.vue')
      },
      {
        path: 'productDisposal',
        name: '제품 폐기 처리',
        component: () => import('../views/ProductionReceipt/ProductDisposal.vue')
      },
      {
        path: 'productPacking',
        name: '제품출고(택배)',
        component: () => import('../views/ProductionReceipt/ProductPacking.vue')
      },
      {
        path: 'productPackingGen',
        name: '제품출하(일반)',
        component: () => import('../views/ProductionReceipt/ProductPackingGen.vue')
      },
      {
        path: 'returnPost',
        name: '택배 반송 입고',
        component: () => import('../views/ProductionReceipt/ReturnPost.vue')
      },
      {
        path: 'returnGen',
        name: '일반출하 반송 입고',
        component: () => import('../views/ProductionReceipt/ReturnGen.vue')
      },
      {
        path: 'palletInfo',
        name: '파레트 정보',
        component: () => import('../views/PurchaseReceipt/PalletInfo.vue')
      },
      {
        path: 'inventoryCount',
        name: '창고재고실사',
        component: () => import('../views/ProductionReceipt/InventoryBaseCount.vue')
      },
      {
        path: 'productQrcode',
        name: '파레트번호 QR출력',
        component: () => import('../views/GoodsQrCode/PalletNoQrPrint.vue')
      },
      {
        path: 'mypage',
        name: '비번변경',
        component: MyPage
      },
      {
        path: 'qrCodeScan',
        name: 'QR코드 스캔',
        component: () => import('../views/GoodsQrCode/QrcodeReader.vue')
      },
      {
        path: 'employee',
        name: '사용자 등록',
        component: () => import('../views/Employee.vue')
      },
      {
        path: 'regLocation',
        name: '창고 등록',
        component: () => import('../views/Location.vue')
      },
      {
        path: 'sapRegFail',
        name: 'SAP등록 실패메세지',
        component: () => import('../views/SapUpdateFailList.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
