<template>
  <v-app>
    <v-app-bar
      app
      dark
      fixed
      height="50px"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="pl-2">
        {{ pageTitle }}
      </v-toolbar-title>

      <v-spacer />
      <span
        class="accent--text"
        @click="onRefresh"
      >{{ userInfo.userName }}</span>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 999999 !important;background-color:rgb(27, 27, 27)"
      fixed
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            HiSFS(MES)
          </v-list-item-title>
          <v-list-item-subtitle>
            POP
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          color="#E65100"
        >
          <div
            v-for="item in menuItems"
            :key="item.no"
          >
            <v-list-item
              v-if="item.title !== &quot;divided&quot;"
              class="v-list-item-active"
              link
              @click.stop="onMenuClcik(item.path)"
            >
              <v-list-item-icon class="mr-8">
                <v-icon :color="item.color">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="item.title === &quot;divided&quot;" />
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ConstDef from '../../../BnhMesVueWin/src/share/ConstDef'
import AppLib from '../share/AppLib'
import axios from 'axios'
import HttpService from '../share/service/HttpService'

export default {
  name: 'MainPage',
  components: {

  },
  data: function () {
    return {
      userInfo: null,
      pageTitle: 'Main Page',
      drawer: false,
      group: null,
      menuItems: null,
      dencity: null
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/').catch(() => {})
      return
    }
    this.$store.commit('saveViewWidth', this.$Q('html').width())
    if (navigator.userAgent.indexOf('M3SM') >= 0) {
      this.$store.commit('saveMobileModel', 'PDA')
    } else if (AppLib.isTabletDevice() === true) {
      this.$store.commit('saveMobileModel', 'TABLET')
    } else {
      this.$store.commit('saveMobileModel', 'MOBILE')
    }
    this.menuItems = [
      { no: 10000, title: '발주자재 입고', icon: 'mdi-note-plus', color: 'yellow', path: '/main/purchaseDeliveryByOrder' },
      { no: 10002, title: '불량자재 반송 출고', icon: 'mdi-note-minus', color: 'yellow', path: '/main/badPurchaseTakeout' },
      { no: 10003, title: '창고 입출 현황', icon: 'mdi-alpha-i-circle-outline', color: 'yellow', path: '/main/productionReceiptList' },
      { no: 10006, title: 'divided' },
      { no: 10010, title: '제품피킹(택배)', icon: 'mdi-map-marker-right', color: 'red', path: '/main/pickingPost' },
      { no: 10011, title: '제품피킹(일반)', icon: 'mdi-map-marker-right', color: 'red', path: '/main/pickingGen' },
      { no: 10014, title: 'divided' },
      { no: 10024, title: '제품출고(택배)', icon: 'mdi-note-minus', color: 'purple', path: '/main/productPacking' },
      { no: 10025, title: '제품출하(일반)', icon: 'mdi-note-minus', color: 'purple', path: '/main/productPackingGen' },
      { no: 10026, title: '택배 반송 입고', icon: 'mdi-truck-delivery-outline', color: 'purple', path: '/main/returnPost' },
      { no: 10027, title: '일반출하 반송 입고', icon: 'mdi-truck-delivery-outline', color: 'purple', path: '/main/returnGen' },
      { no: 10030, title: 'divided' },
      { no: 10032, title: '완제품 입고', icon: 'mdi-note-plus', color: 'red', path: '/main/productDeliveryByOrder' },
      { no: 10034, title: 'divided' },
      { no: 10040, title: '파레트 위치이동', icon: 'mdi-map-marker-right', color: 'yellow', path: '/main/locationMove' },
      { no: 10042, title: '기초재고 조사', icon: 'mdi-counter', color: 'yellow', path: '/main/inventoryCount' },
      { no: 10044, title: '파레트간 제품이동', icon: 'mdi-map-marker-left', color: 'yellow', path: '/main/palletMove' },
      { no: 10045, title: '제품 폐기처리', icon: 'mdi-map-marker-left', color: 'yellow', path: '/main/productDisposal' },
      { no: 10046, title: 'divided' },
      { no: 10048, title: '파레트 정보', icon: 'mdi-tray-full', color: 'purple', path: '/main/palletInfo' },
      { no: 10060, title: 'divided' },
      { no: 10090, title: '파레트번호 QR출력', icon: 'mdi-qrcode', color: 'yellow', path: '/main/productQrcode' },
      { no: 10120, title: 'divided' },
      { no: 10130, title: '로그아웃', icon: 'mdi-logout', color: 'white', path: '/logout' },
      { no: 10132, title: '비번변경', icon: 'mdi-lock', color: 'white', path: '/main/mypage' },
      { no: 10140, title: 'QRCode 스캔', icon: 'mdi-qrcode-scan', color: 'white', path: '/main/qrCodeScan' },
      { no: 10142, title: 'SAP 동기화', icon: 'mdi-refresh', color: 'white', path: 'sapRefresh' }
    ]
    if (this.userInfo.userId.toLowerCase() === 'system') {
      this.menuItems.push({ no: 20000, title: 'divided' })
      this.menuItems.push({ no: 20010, title: '사용자등록', icon: 'mdi-account-plus', color: 'white', path: '/main/employee' })
      this.menuItems.push({ no: 20020, title: '창고등록', icon: 'mdi-home', color: 'white', path: '/main/regLocation' })
      this.menuItems.push({ no: 20030, title: 'SAP등록 실패조회', icon: 'mdi-message-alert', color: 'white', path: '/main/sapRegFail' })
    }
    const where = this.$_.findWhere(this.menuItems, { path: this.$route.path })
    if (where === undefined) { this.pageTitle = 'BNH코스메틱 POP' } else { this.pageTitle = this.$route.name }
    const sysParam = this.$_sp.runNoEncodeFindProc('spFindAllSysParamByNoSql', {})
    const baseCode = this.$_sp.runNoEncodeFindSqlProc('공통코드', null)
    const location = this.$_sp.runNoEncodeFindSqlProc('장소정보', null)
    axios.all([sysParam, baseCode, location])
      .then(axios.spread((...reponse) => {
        if (reponse[0].result === ConstDef.SUCCESS) {
          this.$store.commit('saveSysParam', this.$_sp.MakeModel(reponse[0]))
          this.$store.commit('saveBaseData', this.$_sp.MakeModel(reponse[1]))
          this.$store.commit('saveLocation', this.$_sp.MakeModel(reponse[2]))
        }
      }))
      .catch(error => {
        this.isLoading = false
        if (error.response.status === ConstDef.GENERRAL_ERROR) {
          this.$snotify.error(error.response.data.message)
        } else {
          this.$snotify.error(`에러코드 : ${error.response.status}`)
        }
      })
  },
  mounted () {
    this.dencity = window.devicePixelRatio
    if (this.dencity > 2) {
      this.$Q('html').css('font-size', '18px')
    }
  },
  updated () {
    this.pageTitle = this.$route.name
  },
  methods: {
    onMenuClcik (path) {
      if (path === 'sapRefresh') {
        this.drawer = !this.drawer
        this.$snotify.info('동기화 중입니다. 시간이 다소 걸릴수 있습니다.')
        HttpService.reqPost(null, '/updateSapInOutList')
        return
      }
      this.drawer = !this.drawer
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    onRefresh () {
      location.reload(true)
    }
  }
}
</script>
<style lang="scss">
  .v-divider{
    border-color: rgb(155, 0, 0)!important;
    color: rgb(27, 27, 27);
  }
</style>
