<template>
  <div id="app">
    <router-view />
    <vue-snotify />
  </div>
</template>

<script>
import koMessages from './language/ko.json'
import { locale, loadMessages } from 'devextreme/localization'
import axios from 'axios'
import router from './router'
import ConstDef from '../../BnhMesVueWin/src/share/ConstDef'

export default {
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
  created () {
    loadMessages(koMessages)
    locale(navigator.language)

    const arr = window.location.host.split(':')
    const host = window.location.protocol + '//' + arr[0]
    if (process.env.NODE_ENV === 'production') {
      ConstDef.apiUrl = host + ConstDef.apiUrlProduction
      ConstDef.stompUrl = `${host}${ConstDef.stompPortProduction}`
    } else {
      ConstDef.apiUrl = host + ConstDef.apiUrlEvaluation
      ConstDef.stompUrl = `${host}${ConstDef.stompPortEvaluation}`
    }
    console.log(ConstDef.apiUrl)
    console.log(ConstDef.stompUrl)
  }
}
axios.interceptors.response.use(response => response, (error) => {
  console.log('interceptors Error')
  console.log(error.response)
  if (error.response === undefined) {
    alert('시스템 이상 잠시후 다시 접속 하세요')
    return Promise.reject(error)
  } else if (error.response.status === ConstDef.FORBIDDEN) {
    alert('권한 없음')
  } else if (error.response.status === ConstDef.UNAUTHORIZED) {
    router.replace('/')
  } else if (error.response.status === ConstDef.BAD_REQUEST || error.response.status === ConstDef.NOT_FOUND) {
    console.log('others System Error')
    alert('시스템 이상(Erro Code:' + error.response.status + ')')
    return Promise.reject(error)
  } else if (error.response.data.status === ConstDef.DUPLICATE_LOGIN) {
    console.log(error.response)
    alert(error.response.data.message)
    router.replace('/')
  } else {
    console.log(error)
    return Promise.reject(error)
  }
})
</script>

<style lang="scss">

</style>
